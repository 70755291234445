import 'animate.css/animate.min.css';
import { ContainerWrapper, Layout, SEO } from '../components/common';
import { AboutUs, Footer, Intro, Movies, MusicVideos, Photography } from '../components/theme';
import React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import { Advertisements } from '../components/theme/Advertisements';

export default () => (
  <Layout>
    <SEO />
    <ParallaxProvider>
      <Intro />
    </ParallaxProvider>

    <ContainerWrapper>
      <AboutUs />
      <Advertisements />
      <MusicVideos />
      <Movies />
      {/* <Photography /> */}
      {/* <Contact /> */}
      <Footer />
    </ContainerWrapper>
  </Layout>
);

import { ComponentWrapper, Container, GridTest2, H3, VideoWrapper } from '../../../components/common';
import React from 'react';
import ReactPlayer from 'react-player';

export const Advertisements = () => (
  <ComponentWrapper as={Container} id="advertisements">
    <H3>Reklama</H3>

    <VideoWrapper>
      <GridTest2>
        <div>
          <ReactPlayer width="100%" height="100%" url="https://maxrec.pl/files/REKLAMA%20LENOVO.mp4" controls />
        </div>

        <div>
          <ReactPlayer
            width="100%"
            height="100%"
            url="https://maxrec.pl/files/KITZINI%20BENTO%20BOX%20WITH%20TITLES%20V1.3.mp4"
            controls
          />
        </div>
        <div>
          <ReactPlayer
            width="100%"
            height="100%"
            url="https://maxrec.pl/files/KITZINI%20PLANTS%20SILVER%20V1.3.mp4"
            controls
          />
        </div>

        <div>
          <ReactPlayer width="100%" height="100%" url="https://maxrec.pl/files/MASA%c5%bbER%20REKLAMA.mp4" controls />
        </div>
      </GridTest2>
    </VideoWrapper>
  </ComponentWrapper>
);
